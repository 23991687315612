import { FeatureFlags } from 'common/feature_flags';
import { omit } from 'lodash';

// WARNING: the values of the assets determine SOQL api values,
// so change them at your peril
export const ASSET_TYPES = {
  CALENDAR: 'calendar',
  CHART: 'chart',
  DATASET: 'dataset',
  DATA_LENS: 'data_lens', // Yes data_lens is deprecated but deleting this breaks lots of tests
  EXTERNAL_DATASET: 'external',
  FILE: 'blob',
  FILTERED_VIEW: 'filter',
  FORM: 'form',
  MAP: 'map',
  STORY: 'story',
  DRAFT: 'draft',
  USER: 'user',
  AGENT: 'agent',
  SCHEDULE: 'schedule'
};

export const NO_DATA_LENS_ASSET_TYPES = omit(ASSET_TYPES, 'DATA_LENS');

export const TABS = {
  ALL: 'all',
  FAILURE: 'failure',
  DELETED: 'deleted',
  APPROVALS: 'approvals'
};

// WARNING: the values of the assets correspond to SOQL api values,
// so change them at your peril
export const EVENTS = {
  APPROVAL_PROCESS: 'ApprovalProcess',
  ASSET_CREATED: 'AssetCreated',
  ASSET_DELETED: 'AssetDeleted',
  ASSET_RESTORED: 'AssetRestored',
  ASSET_METADATA_CHANGED: 'AssetMetadataChanged',
  ASSET_OWNER_CHANGED: 'AssetOwnerChanged',
  ASSET_PERMISSIONS_CHANGED: 'AssetPermissionsChanged',
  COLLABORATOR_ADDED: 'CollaboratorAdded',
  COLLABORATOR_REMOVED: 'CollaboratorRemoved',
  DATA_UPDATE_STARTED: 'DataUpdateStarted',
  DATA_UPDATE_IN_PROGRESS: 'DataUpdateInProgress',
  DATA_UPDATE_SUCCESS: 'DataUpdateSuccess',
  DATA_UPDATE_SUCCESS_WITH_ERRORS: 'DataUpdateSuccessWithDataErrors',
  DATA_UPDATE_FAILURE: 'DataUpdateFailure',
  DRAFT_CREATED: 'DraftCreated',
  DRAFT_DELETED: 'DraftDeleted',
  DRAFT_PUBLISHED: 'DraftPublished',
  PLATFORM_ADMIN_ACCESS_GRANT_CREATED: 'PlatformAdminAccessGrantCreated',
  PLATFORM_ADMIN_ACCESS_GRANT_REVOKED: 'PlatformAdminAccessGrantRevoked',
  PLATFORM_ADMIN_LOGIN: 'PlatformAdminLogin',
  SCHEDULE_SUCCEEDED: 'ScheduleSucceeded',
  SCHEDULE_FAILED: 'ScheduleFailed',
  SCHEDULE_FAILED_FATALLY: 'ScheduleFailedFatally',
  SCHEDULE_PAUSED: 'SchedulePaused',
  SCHEDULE_PAUSE_TOGGLE: 'SchedulePauseToggle',
  SCHEDULE_UNPAUSED: 'ScheduleUnpaused',
  USER_ACCOUNT_ACTIVATED: 'UserAccountActivated',
  USER_ADDED: 'UserAdded',
  USER_REMOVED: 'UserRemoved',
  USER_ROLE_CHANGED: 'UserRoleChanged',
  USER_DISABLED: 'UserDisabledV2',
  USER_ENABLED: 'UserEnabledV2',
  STORY_DRAFT_CREATED: 'StoryDraftCreated',
  AGENT_CREATED: 'AgentCreated',
  AGENT_DELETED: 'AgentDeleted',
  AGENT_ONLINE: 'AgentOnline',
  AGENT_OFFLINE: 'AgentOffline',
  PLUGIN_CONFIGURED: 'PluginConfigured',
  PLUGIN_DELETED: 'PluginDeleted'
};

export const COMMON_ACTIONS = {
  CHANGE_DIMENSIONS: 'CHANGE_DIMENSIONS',
  TOGGLE_FILTERS: 'TOGGLE_FILTERS',
  STORE_NUMBER_OF_DAYS_RESTORABLE: 'STORE_NUMBER_OF_DAYS_RESTORABLE',
  SHOW_RESTORE_MODAL: 'SHOW_RESTORE_MODAL',
  HIDE_RESTORE_MODAL: 'HIDE_RESTORE_MODAL',
  API_CALL_IN_PROGRESS: 'API_CALL_IN_PROGRESS',
  API_CALL_SUCCESS: 'API_CALL_SUCCESS',
  API_ERROR: 'API_ERROR',
  SHOW_SUCCESS_MESSAGE: 'SHOW_SUCCESS_MESSAGE',
  HIDE_SUCCESS_MESSAGE: 'HIDE_SUCCESS_MESSAGE'
};

export const FILTER_ACTIONS = {
  CHANGE_TAB: 'CHANGE_TAB',
  CHANGE_EVENTS: 'CHANGE_EVENTS',
  CHANGE_ASSET_TYPE: 'CHANGE_ASSET_TYPE',
  CHANGE_EVENT: 'CHANGE_EVENT',
  CHANGE_DATE_RANGE: 'CHANGE_DATE_RANGE',
  CHANGE_AFFECTED_ITEM_SEARCH: 'CHANGE_AFFECTED_ITEM_SEARCH',
  CHANGE_INITIATED_BY_SEARCH: 'CHANGE_INITIATED_BY_SEARCH',
  CLEAR_ALL_FILTERS: 'CLEAR_ALL_FILTERS',
  SET_ACTIVE_FILTERS: 'SET_ACTIVE_FILTERS',
  STORE_FILTER_QUERY_MAPPING: 'STORE_FILTER_QUERY_MAPPING'
};

export const ORDER_ACTIONS = {
  CHANGE_ORDER: 'CHANGE_ORDER'
};


export const PAGE_ACTIONS = {
  STORE_ROW_COUNT: 'STORE_ROW_COUNT',
  STORE_PAGE: 'STORE_PAGE',
  RESET_PAGE: 'RESET_PAGE'
};

export const TABLE_ACTIONS = {
  FETCH_DATA: 'FETCH_DATA',
  STORE_DATA: 'STORE_DATA',
  SHOW_DETAILS: 'SHOW_DETAILS',
  HIDE_DETAILS: 'HIDE_DETAILS',
  STORE_RESTORABLE_STATUS: 'STORE_RESTORABLE_STATUS'
};

export const DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc'
};
